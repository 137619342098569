import { Club, TeamType } from "../../types/types";
import { getGuid } from "../../utils";
import DantamLogo from './images/logos/dantam.png'
import UniqueLogo from './images/logos/unique.png'
import TfcFredericiaLogo from './images/logos/tfc.png'
import NordavindLogo from './images/logos/nordavind.png'
import SønderborgLogo from './images/logos/sønderborg.png'
import OtscLogo from './images/logos/otsc.png'
import Crew74Logo from './images/logos/crew74.png'
import GtfcLogo from './images/logos/gtfc.png'
import EverestLogo from './images/logos/everest.png'
import StovnerLogo from './images/logos/stovner.png'
import HobroLogo from './images/logos/hobro.png'
import AarhusSkLogo from './images/logos/aarhussk.png'
import NtskLogo from './images/logos/ntsk.png'
import RoyalBernLogo from './images/logos/royalbern.png'
import TfcBredaLogo from './images/logos/breda.png'
import DrammenLogo from './images/logos/dtsk.png'
import IllampuyalLogo from './images/logos/ilampuyal.png'
import StPatricksLogo from './images/logos/stpatricks.png'
import TikiTakaLogo from './images/logos/tikitaka.png'
import BlueStarsLogo from './images/logos/bluestars.png'
import EnnepetalLogo from './images/logos/tsvennepetal.png'
import VejleLogo from './images/logos/vejlepng.png'
import grindstedYoungstarsLogo from './images/logos/youngstars.png'


export enum Nationality {
  DENMARK = "DENMARK",
  NORWAY = "NORWAY",
  NETHERLANDS = "NETHERLANDS",
  ENGLAND = "ENGLAND",
  FRANCE = "FRANCE",
  SWITZERLAND = "SWITZERLAND",
  BELGIUM = "BELGIUM",
  GERMANY = "GERMANY",
}

const DantamIf = { id: "dantamif", name: "Dantam IF", logo: DantamLogo } as Club;
const UniqueFc = { id: "uniquefc", name: "Unique FC", logo: UniqueLogo } as Club;
const TfcFredericia = { id: "tfcFredericia", name: "TFC Fredericia", logo: TfcFredericiaLogo } as Club;
const Nordavind = { id: "nordavind", name: "Nordavind", logo: NordavindLogo } as Club;
const Stovner = { id: "stovner", name: "Stovner", logo: StovnerLogo } as Club;
const Sønderborg = { id: "sønderborg", name: "Sønderborg", logo: SønderborgLogo } as Club;
const Otsc = { id: "otsc", name: "OTSC", logo: OtscLogo } as Club;
const Crew74 = { id: "crew74", name: "Crew74", logo: Crew74Logo } as Club;
const Gtfc = { id: "gtfc", name: "GTFC", logo: GtfcLogo } as Club;
const Everest = { id: "everest", name: "Everest", logo: EverestLogo } as Club;
const Noreel = { id: "noreel", name: "Noreel SK", logo: "" } as Club;
const Hobro = { id: "hobro", name: "Hobro", logo: HobroLogo } as Club;
const AarhusSK = { id: "aarhussk", name: "Aarhus SK", logo: AarhusSkLogo } as Club;
const NTSK = { id: "ntsk", name: "NTSK", logo: NtskLogo } as Club;
const RoyalBern = { id: "royalbern", name: "Royal Bern", logo: RoyalBernLogo } as Club;
const TfcBreda = { id: "tfcbreda", name: "TFC Breda", logo: TfcBredaLogo } as Club;
const Drammen = { id: "drammen", name: "Drammen", logo: DrammenLogo } as Club;
const Illampuyal = { id: "illampuyal", name: "Illampuyal", logo: IllampuyalLogo } as Club;
const StPatricks = { id: "stpatrickscollege", name: "St. Patricks", logo: StPatricksLogo } as Club;
const TikiTakaBergen = { id: "tikitakabergen", name: "TikiTakaBergen", logo: TikiTakaLogo } as Club;
const PambalSquad = { id: "pambalsquad", name: "Pambal Squad", logo: '' } as Club;
const ScBlueStarLausanne = { id: "scbluestarlausanne", name: "SC Blue Star Lausanne", logo: BlueStarsLogo } as Club;
const TSVEnnepetal = { id: "tsvennepetal", name: "TSV Ennepetal", logo: EnnepetalLogo } as Club;
const Vejle = { id: "vejle", name: "Vejle", logo: VejleLogo } as Club;
const UKLocalBoyz = { id: "uklocalboyz", name: "UK Local Boyz", logo: "" } as Club;
const GrindstedYoungstars = { id: "grindstedyoungstars", name: "Grindsted Youngstars", logo: grindstedYoungstarsLogo } as Club;


export interface ExtendedTeamType extends TeamType {
  nationality: Nationality;
  potMatches: number[];
  potId: number;
  opponents: Array<ExtendedTeamType>;
}

export interface Pot {
  potId: number;
  teams: Array<ExtendedTeamType>;
}

export const pot1 = {
  potId: 1,
  teams: [
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam IF A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam IF B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique FC Red",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TikiTakaBergen,
      teamName: "TikiTakaBergen",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Stovner,
      teamName: "Stovner Giants",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Sønderborg,
      teamName: "Sønderborg A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcFredericia,
      teamName: "TFC Fredericia A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique FC Black",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
  ],
} as Pot;

export const pot2 = {
  potId: 2,
  teams: [
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Nordavind,
      teamName: "Nordavind White",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Noreel,
      teamName: "Noreel SK Red",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Noreel,
      teamName: "Noreel SK Blue",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Everest,
      teamName: "Everest",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Crew74,
      teamName: "Crew74 Wolves",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
  ],
} as Pot;

export const pot3 = {
  potId: 3,
  teams: [
    {
      id: getGuid(),
      club: TfcBreda,
      teamName: "TFC Breda",
      nationality: Nationality.NETHERLANDS,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Gtfc,
      teamName: "GTFC A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: RoyalBern,
      teamName: "Royal Bern",
      nationality: Nationality.SWITZERLAND,
      potMatches: [],
      potId: 3,
      opponents: [],
    },

    {
      id: getGuid(),
      club: NTSK,
      teamName: "NTSK",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Nordavind,
      teamName: "Nordavind Black",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: AarhusSK,
      teamName: "Aarhus SK",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Drammen,
      teamName: "Drammen",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
  ],
} as Pot;

export const pot4 = {
  potId: 4,
  teams: [
    {
      id: getGuid(),
      club: PambalSquad,
      teamName: "Pambal Squad",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: ScBlueStarLausanne,
      teamName: "SC Blue Star Lausanne",
      nationality: Nationality.SWITZERLAND,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TSVEnnepetal,
      teamName: "TSV Ennepetal",
      nationality: Nationality.GERMANY,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Illampuyal,
      teamName: "Ilampuyal",
      nationality: Nationality.BELGIUM,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Vejle,
      teamName: "Vejle",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UKLocalBoyz,
      teamName: "UK Local Boyz",
      nationality: Nationality.ENGLAND,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: StPatricks,
      teamName: "St. Patricks College",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: GrindstedYoungstars,
      teamName: "Grindsted Youngstars",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
  ],
} as Pot;
